import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';


const WHITE = '#FFFFFF';
const BLACK = '#000000';
const RED = '#bf0a00';
const BORDER_HEIGHT = 3;
const SHADOW_HEIGHT = 2;
const ROTATION = 20;


const styles = {
  button: {
    background: 'transparent',
    border: `2px solid ${RED}`,
    borderRadius: 20,
    color: BLACK
  }
}

class RedButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      borderBottom: BORDER_HEIGHT,
      borderTop: 0,
      shadowOffset: SHADOW_HEIGHT,
      degreesRotated: ROTATION,
    };
  }

  // -------------------------
  // -------  methods --------
  // -------------------------

  // -------------------------------
  // ------- render methods --------
  // -------------------------------
  render() {
    const { hovering } = this.state;

    const styleButton = hovering ? { ...styles.button, background: RED, color: WHITE } : styles.button;

    return (
      <Button
        style={this.props.disabled ? { background: '#C5C5C5', color: '#717885', ...this.props.style } : { ...styleButton, ...this.props.style }}
        onClick={this.props.onClick}
        loading={this.props.loading}
        disabled={this.props.disabled}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        { this.props.label || this.props.children }
      </Button>
    );
  }
}


RedButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};


export { RedButton };
