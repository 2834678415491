export const SET_HEADER_VISIBILITY = 'web/SET_HEADER_VISIBILITY';
export const SET_LOADING = 'web/SET_LOADING';
export const SET_USER_SESSION = 'web/SET_USER_SESSION';
export const SET_SELECTED_VIEW = 'web/SET_SELECTED_VIEW';
export const SUBSCRIBE_USER = 'web/SUBSCRIBE_USER';
export const GET_POSTS = 'web/GET_POSTS';
export const SET_POSTS = 'web/SET_POSTS';
export const GET_POST_DETAIL = 'web/GET_POST_DETAIL';
export const SET_POST_DETAIL = 'web/SET_POST_DETAIL';
export const GET_POSTS_BYNAME = 'web/GET_POSTS_BYNAME';
export const SET_POSTS_BYNAME = 'web/SET_POSTS_BYNAME';
export const GET_TAGS = 'web/GET_TAGS';
export const SET_TAGS = 'web/SET_TAGS';
export const REGISTER_WEBINAR  = 'web/REGISTER_WEBINAR'
