import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "../configureStore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WebLayout from "../containers/web/layout";

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" render={(props) => <WebLayout {...props} />} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
