export const WHITE = "#FFFFFF";
export const DARK_BLACK = "rgb(6, 6, 5)";
export const BLACK = "#1D1A3E";
export const LIGHT_BLACK = "rgb(62, 63, 65)";
export const TABLE_HEADER = "#757575";
export const GRAY = "#717885";
export const LIGHT_GRAY = "#C5C5C5";
export const BACKGROUND_GRAY = "rgba(217, 209, 201, 0.3)";
export const BACKGROUND_LIGHT_GRAY = "#F0F2F5";
export const SAND = "#F3F0EF";
export const GREEN = "#476628";
export const LIGHT_GREEN = "#709D42";
export const METAL = "#6B8596";
export const YELLOW = "#fdbd39";
export const SHADOW = "rgba(100, 100, 100, 0.25) 0px 2px 4px";
export const RED = "#BF0A30";
export const BLUE = "#7798BF";
export const EMEAT_BLUE = "#161c2a";
export const GRAY_WEB = "rgb(243, 243, 243)";
export const CONTAINERS_BOX_SHADOW = '0px 0px 45px rgb(0 0 0 / 10%)';
export const ORANGE = "#FF8A3D";
export const BROWN = "#3D291C";
