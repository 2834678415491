import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Semantic from 'semantic-ui-react';
import formatter from '../utils/formatter';
import transitions from '../utils/transitions';
import eventManager from '../utils/eventManager';
import keys from '../utils/keys';
import { isEqual } from 'lodash';

const OPENING_EVENT = 'open-menu-popup';

const INITIAL_STATE = {
  popupVisible: false,
  margin: -20,
  opacity: 0
};


class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.popUpKey = keys.generate();

    this.callbackID = eventManager.on(OPENING_EVENT, (key) => {
      if (this.popUpKey !== key) {
        this.setState({ ...INITIAL_STATE });
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  animateIn() {
    const marginParams = {
      initialValue: this.state.margin,
      finalValue: 0,
      duration: 300
    };

    const opacityParams = {
      initialValue: this.state.opacity,
      finalValue: 1,
      duration: 300
    };

    transitions.easePolyOut(marginParams, (response) => {
      if (!this.unmounted) {
        this.setState({ margin: response.value });
      }
    });

    transitions.easePolyOut(opacityParams, (response) => {
      if (!this.unmounted) {
        this.setState({ opacity: response.value });
      }
    });
  }

  open() {
    this.opening = true;
    eventManager.emit(OPENING_EVENT, this.popUpKey);

    this.setState({ popupVisible: true }, () => {
      this.animateIn();

      setTimeout(() => {
        this.opening = false;
      }, 300);
    });
  }

  close() {
    if (!this.opening) {
      this.setState({ ...INITIAL_STATE });
    }
  }

  render() {
    const { popupVisible, margin, opacity } = this.state;
    const { trigger, children, position } = this.props;

    return (
      <Semantic.Popup
        trigger={trigger}
        on='hover'
        hoverable
        flowing
        open={popupVisible}
        onOpen={() => { this.open(); }}
        onClose={() => { this.close(); }}
        position={position || 'bottom left'}
        style={{ boxShadow: '0px 0px 20px rgba(0,0,0,0.3)', marginTop: margin, opacity }}
      >
        { children }
      </Semantic.Popup>
    );
  }
}


Popup.propTypes = {
  trigger: PropTypes.element,
  children: PropTypes.element,
  position: PropTypes.string
};


export { Popup };
