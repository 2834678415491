import * as actionsTypes from './types';
import { MOBILE_BREAKPOINT } from '../../../utils/constants';


const INITIAL_STATE = {
  language: 'en',
  mobile: window.innerWidth < MOBILE_BREAKPOINT
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case actionsTypes.SET_MOBILE:
        return { ...state, mobile: action.payload };
    default:
      return state;
  }
};
