import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Label } from 'semantic-ui-react';
import _ from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';
import i18n from '../utils/i18n.json';

const { errorRequired, errorMaxSize, placeHolder, errorFormat, placeHolder2 } = i18n.file;


class FileInput extends Component {
  constructor(props) {
    super(props);
    let valid;
    let placeholder = props.placeholder;

    if (props.defaultValue && props.defaultValue.name && props.defaultValue.value) {
      valid = true;
      placeholder = props.defaultValue.name;
    } else {
      valid = !props.required;
    }

    this.state = {
      value: props.defaultValue ? props.defaultValue.value : '',
      valid,
      message: props.required ? errorRequired[props.language].replace('#label',props.label.toLowerCase()) : '',
      dirty: false,
      placeholder,
      errorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue && nextProps.defaultValue.value) {
      this.setState({
        value: nextProps.defaultValue.value,
        placeholder: nextProps.defaultValue.name,
        valid: true,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    this.setState({ dirty: true, errorVisible: false });

    if (e.target.files.length) {
      const currentFile = e.target.files[0];
      const name = currentFile.name;
      let format = currentFile.name.split('.');
      format = format[format.length - 1];
      const size = currentFile.size / 1024;

      if (this.props.maxSize && size > this.props.maxSize) {
        this.setState({
          valid: false,
          value: '',
          message: errorMaxSize[this.props.language].replace('#maxSize', this.getMaxSizeLabel()),
          placeholder: placeHolder[this.props.language],
        });
      } else if (this.props.extention && !this.validExtension(format)) {
        this.setState({
          valid: false,
          value: '',
          message: errorFormat[this.props.language].replace('#label',this.props.label.toLowerCase()),
          placeholder: placeHolder2[this.props.language].replace('#format',format),
        });
      } else {
        this.getBase64(currentFile, (base64) => {
          this.setState({
            valid: true,
            value: base64,
            message: '',
            placeholder: name,
          });
        });
      }
    }
  }

  getBase64(currentFile, callback) {
    const reader = new FileReader();
    let base64;

    // asignar callback
    reader.onloadend = () => {
      base64 = reader.result;

      if (this.props.fullBase64 === false) {
        base64 = reader.result.split(',')[1];
        callback(base64);
      } else {
        callback(base64);
      }
    };

    reader.readAsDataURL(currentFile);
  }

  getMaxSizeLabel() {
    const { maxSize } = this.props;

    if (maxSize < 1024) {
      return `${maxSize} KB`;
    }

    return `${maxSize / 1024} MB`;
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return {
      name: this.state.placeholder,
      value: this.state.value,
    };
  }

  validExtension(format) {
    const { extention } = this.props;

    if (typeof extention === 'string') {
      return this.props.extention === format.toLowerCase();
    }

    const foundExtensions = extention.filter((item) => item === format.toLowerCase());
    return foundExtensions.length;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    let valid;
    let placeholder = this.props.placeholder;

    if (this.props.defaultValue && this.props.defaultValue.name && this.props.defaultValue.value) {
      valid = true;
      placeholder = this.props.defaultValue.name;
    } else {
      valid = !this.props.required;
    }

    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue.value : '',
      valid,
      message: this.props.required ? errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()) : '',
      dirty: false,
      placeholder,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    const labelString = this.props.required ? `${this.props.label} *` : this.props.label;

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          { labelString }
        </label>

        <label style={{ ...styles.labelcontent, ...finalInputStyle }}>
          <label style={styles.labelFile}><span>{this.state.placeholder}</span></label>

          <Input
            type="file"
            name={this.props.name}
            className={className}
            onChange={this.onChange.bind(this)}
            disabled={this.props.readOnly}
            style={styles.inputfile}
          />

          <div style={{ ...styles.buttom, ...this.props.buttonStyle }}>
            { this.props.buttonLabel }
          </div>
        </label>

        { this.renderErrorLabel() }
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  buttom: {
    height: '40px',
    background: '#E0E1E2',
    color: 'rgba(0,0,0,.6)',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
  labelFile: {
    flex: 1,
    color: 'rgba(191,191,191,.87)',
    marginRight: '5px',
    paddingLeft: '15px',
    fontWeight: 500,
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  labelcontent: {
    display: 'flex',
    width: '100%',
    marginRight: '20px',
    border: '1px solid #D8D8D8',
    marginBottom: 0,
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  buttonLabel: PropTypes.string.isRequired,
  buttonStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  setFormData: PropTypes.func.isRequired,
  fullBase64: PropTypes.bool,
  extention: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  maxSize: PropTypes.number,
  defaultValue: PropTypes.object,
  language: PropTypes.string,
};


export default FileInput;
