import { combineReducers } from 'redux';
import appContainer from './app';
import webContainer from './web';


const containers = {
  reducers: combineReducers({
    app: appContainer.reducer,
    web: webContainer.reducer,
  }),
  sagas: {
    web: webContainer.saga,
  },
};


export default containers;
