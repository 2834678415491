import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Image } from "semantic-ui-react";
import { Route, Switch } from "react-router-dom";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import Header from "./Header";
import formatter from "../../../utils/formatter";
import eventManager from "../../../utils/eventManager";
import { FadeInOutView } from "../../../components";
import { BLUE, DARK_BLACK, GRAY } from "../../../colors";
import { MOBILE_BREAKPOINT } from "../../../utils/constants";
import Landing from "../views/Landing";
import { setMobile } from "../../app/store/actions";
import LinkedIn from '../../../assets/LinkedIn.png';
import translate from '../../../utils/i18n/footer.json';
import logoImage from '../../../assets/text-logo.png';
import decorativeImage from '../../../assets/decorative-element.png';

const { Row, Column } = Grid;

const styles = {
  mainFooterContainer: {
    background: DARK_BLACK,
    color: 'white',
    boxShadow: '0 -3px 3px rgb(0,0,0,0.2)',
    zIndex: 20,
    margin: 0,
    width: '100%'
  },
  mainContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    margin: 0,
    overflowY: 'scroll'
  },
  cookiesContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    background: 'rgba(6, 6, 5, 0.8)',
    backdropFilter: 'saturate(180%) blur(20px)',
    WebkitBackdropFilter: 'saturate(180%) blur(20px)',
    zIndex: 100,
    paddingTop: 10,
    paddingBottom: 10,
    boxShadow: '0px 0px 10px rgba(0,0,0,0.4)'
  },
  secondRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `0.1px groove ${GRAY}`,
    paddingBottom: 15
  },
  decorativeImage: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
  }
};

class WebLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footerVisible: true,
      mainFooterHeight: 0,
      scrollPosition: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    this.initNavigationListeners();

    window.onresize = () => {
      this.setWindowDimensions();
      this.setFooterHeight();
    };

    this.setWindowDimensions();
    this.setFooterHeight();

    window.onscroll = () => {
      const scrollPosition = window.pageYOffset;
      this.setState({ scrollPosition });
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  // ---------------------
  // ----- methods -------
  // ---------------------
  setFooterHeight() {
    const mainFooter = document.getElementById("main-footer");

    if (mainFooter) {
      this.setState({ mainFooterHeight: mainFooter.offsetHeight });
    }
  }

  setWindowDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

    if (window.innerWidth < MOBILE_BREAKPOINT) {
      this.props.setMobile(true);
    } else {
      this.props.setMobile(false);
    }
  }

  getMinHeight() {
    const { windowHeight, mainFooterHeight } = this.state;
    return windowHeight - mainFooterHeight;
  }

  navigate(url) {
    let eventType = "start-navigation";

    if (window.innerWidth < MOBILE_BREAKPOINT) {
      eventType = "start-navigation-mobile";
    }

    eventManager.emit(eventType, () => {
      this.props.history.push(url);
    });
  }

  initNavigationListeners() {
    this.navigateCallbackID = eventManager.on("navigate", (url) => {
      this.navigate(url);
    });

    this.middleNavigationCallbackID = eventManager.on("start-navigation-middle", () => {
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        this.setState({ footerVisible: false });
      }
    });

    this.finishedNavigationCallbackID = eventManager.on("start-navigation-finished", () => {
      this.setState({ footerVisible: true });
    });
  }

  // ----------------------------
  // ----- render methods -------
  // ----------------------------
  renderMainFooter() {
    const { language, mobile } = this.props;
    const { footerVisible } = this.state;

    if (!footerVisible) {
      return null;
    }

    if (mobile) {
      return (
        <Row centered id="main-footer" style={styles.mainFooterContainer}>
          <Column>
            <Grid centered stackable>
              <Column width={16} style={{ paddingBottom: 0, textAlign: 'center' }}>
                <center><Image src={logoImage} style={{ width: 60, height: 20, marginTop: 10 }} /></center>
              </Column>

              <Column>
                <div style={styles.secondRow}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a
                      href={"https://emeat.io/contact"}
                      style={{ color: BLUE, display: 'flex', alignItems: 'center', marginTop: 5 }}
                    >
                      Contact Us
                    </a>
                  </div>

                  <div>
                    <a href="https://www.linkedin.com/showcase/emeat/posts/?feedView=all" style={{ color: BLUE, display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                      <Image src={LinkedIn} style={{ height: 20, width: 20, marginRight: 5 }} /> LinkedIn
                    </a>
                  </div>
                </div>
              </Column>

              <Column width={16} textAlign="center" style={{ margin: 0 }}>
                <a
                  href={"https://emeat.io/terms-of-service"}
                  style={{ color: BLUE, marginTop: 5 }}
                >
                  {translate.termsService[language]}
                </a>

                <span style={{ color: 'white', marginRight: 5, marginLeft: 5 }}>
                  {translate.separator[language]}
                </span>

                <a
                  href={"https://emeat.io/privacy-policy"}
                  style={{ color: BLUE, marginTop: 5 }}
                >
                  {translate.privacyPolicy[language]}
                </a>
              </Column>

              <Column width={16} textAlign="center">
                <span style={{ color: 'white' }}>
                  {translate.copyright[language]}
                </span>
              </Column>
            </Grid>
          </Column>
        </Row>
      );
    }

    return (
      <Row centered id="main-footer" style={styles.mainFooterContainer}>
        <Column>
          <Grid container>
            <Column>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image src={logoImage} style={{ width: 60, height: 20 }} />
              </div>

              <div style={styles.secondRow}>
                <div>
                  <a href="https://emeat.io/contact" style={{ color: BLUE, display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                    Contact Us
                  </a>
                </div>

                <div>
                  <a href="https://www.linkedin.com/showcase/emeat/posts/?feedView=all" style={{ color: BLUE, display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                    <Image src={LinkedIn} style={{ height: 20, width: 20, marginRight: 5 }} /> LinkedIn
                  </a>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                <div style={{ flex: 1 }}>
                  <span style={{ color: 'white' }}>
                    {translate.copyright[language]}
                  </span>
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div>
                    <a href="https://emeat.io/terms-of-service" style={{ color: BLUE, display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                      {translate.termsService[language]}
                    </a>
                  </div>

                  <span style={{ color: 'white', marginRight: 5, marginLeft: 5 }}>
                    {translate.separator[language]}
                  </span>

                  <div>
                    <a href="https://emeat.io/privacy-policy" style={{ color: BLUE, display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                      {translate.privacyPolicy[language]}
                    </a>
                  </div>
                </div>
              </div>
            </Column>
          </Grid>
        </Column>
      </Row>
    );
  }

  renderViews() {
    const { language, mobile } = this.props;
    const { scrollPosition } = this.state;
    const navigate = this.navigate.bind(this);
    const commonProps = { navigate, language, mobile, scrollPosition };

    const views = (
      <Switch>
        <Route path="/" render={(props) => <Landing {...props} {...commonProps} />} />
      </Switch>
    );

    if (mobile) {
      return (
        <Row only="mobile">
          <Column style={{ padding: 0 }}>
            <FadeInOutView eventType="start-navigation-mobile">
              <Grid style={{ margin: 0, marginTop: 59, marginBottom: 20 }}>
                <Column style={{ padding: 0 }}>{views}</Column>
              </Grid>
            </FadeInOutView>
          </Column>
        </Row>
      );
    }

    return (
      <Row
        only="tablet computer"
        style={{
          minHeight: this.getMinHeight(),
          paddingBottom: 0,
          backgroundColor: "#FCF9F4",
        }}
      >
        <Column>
          <FadeInOutView eventType="start-navigation">
            <Image src={decorativeImage} style={styles.decorativeImage} />

            <Grid style={{ marginTop: 57, padding: 0, marginBottom: 50 }}>
              <Column style={{ padding: 0 }}>{views}</Column>
            </Grid>
          </FadeInOutView>
        </Column>
      </Row>
    );
  }

  render() {
    const { mobile } = this.props;
    const { scrollPosition } = this.state;
    const extraStyle = {};

    if (mobile) {
      extraStyle.width = '100%';
      extraStyle.marginLeft = 0;
    }

    return (
      <div>
        <Header
          navigate={this.navigate.bind(this)}
          mobile={mobile}
          scrollPosition={scrollPosition}
        />

        <Grid style={{ background: "white", marginTop: 0, ...extraStyle }}>
          {this.renderViews()}
          {this.renderMainFooter()}
        </Grid>
      </div>
    );
  }
}


WebLayout.propTypes = {
  history: PropTypes.object,
  language: PropTypes.string,
  mobile: PropTypes.bool,
  setMobile: PropTypes.func,
  selectedView: PropTypes.string,
};


const mapStateToProps = (state) => ({
  language: state.app.language,
  mobile: state.app.mobile,
  selectedView: state.web.selectedView,
});


const actions = {
  setMobile,
};


export default connect(mapStateToProps, actions)(WebLayout);
