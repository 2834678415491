import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { RED, WHITE } from '../../colors';


class InvertedRedButton extends Component {
  state = {
    hovering: false,
  }

  render() {
    const { hovering } = this.state;
    const { children, onClick, fluid, style = {} } = this.props;

    const buttonStyle = {
      background: 'transparent',
      border: `1px solid ${RED}`,
      color: WHITE,
      ...style
    };

    if (hovering) {
      buttonStyle.background = RED;
      buttonStyle.color = WHITE;
    }

    return (
      <Button
        fluid={fluid}
        style={buttonStyle}
        onClick={onClick}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        { children }
      </Button>
    );
  }
}


InvertedRedButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  fluid: PropTypes.bool,
  style: PropTypes.object,
}


export { InvertedRedButton };
