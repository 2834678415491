import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';


export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.SUBSCRIBE_USER, workers.subscribeUser),
    takeLatest(actionsTypes.GET_POSTS, workers.getPosts),
    takeLatest(actionsTypes.GET_POST_DETAIL, workers.getPostDetail),
    takeLatest(actionsTypes.GET_POSTS_BYNAME, workers.getPostsByName),
    takeLatest(actionsTypes.GET_TAGS, workers.getTags),
    takeLatest(actionsTypes.REGISTER_WEBINAR, workers.registerInWebinar)
  ]);
}
