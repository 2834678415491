import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import containers from './containers';


export default () => {
  const initialState = {};
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    containers.reducers,
    initialState,
    compose(...enhancers),
  );

  // run all sagas
  Object.keys(containers.sagas).forEach((sagasName) => {
    const currentSaga = containers.sagas[sagasName];
    sagaMiddleware.run(currentSaga);
  });

  return store;
};
