import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popup, Grid, Icon, Input, Dropdown } from 'semantic-ui-react';

const { Row, Column } = Grid;
const EQUALS_TO = 'equals_to';
const GREATER_THAN = 'greater_than';
const LESS_THAN = 'less_than';
const RANGE = 'range';


class NumberFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      secondValue: '',
      operator: EQUALS_TO,
      popUpVisible: false,
      hovering: false,
    };
  }

  // ----------------------------------
  // ------- life cycle events --------
  // ----------------------------------
  componentDidMount() {
    this.props.updateFilterFunction(this.props.attribute, this.filterFunction.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const differentValue = prevState.value !== this.state.value;
    const differentSecondValue = prevState.secondValue !== this.state.secondValue;
    const differentOperator = prevState.operator !== this.state.operator;

    if (differentValue || differentSecondValue || differentOperator) {
      this.props.filterRequest();
    }
  }


  // ------------------------------
  // ------- handle events --------
  // ------------------------------
  onInputChange(event) {
    const regex = /[^.\d]/g;

    if (!regex.test(event.target.value)) {
      this.setState({ value: event.target.value });
    }
  }

  onSecondInputChange(event) {
    const regex = /[^.\d]/g;

    if (!regex.test(event.target.value)) {
      this.setState({ secondValue: event.target.value });
    }
  }

  onPopUpOpen() {
    this.setState({ popUpVisible: true });
  }

  onPopUpClose() {
    setTimeout(() => {
      if (!this.state.hovering) {
        this.setState({ popUpVisible: false });
      }
    }, 500);
  }

  filterFunction(currentItem) {
    const { attribute } = this.props;

    if (!this.state.value && !this.state.secondValue) {
      return true;
    }

    if (currentItem[attribute] === undefined || currentItem[attribute] === null) {
      return false;
    }

    // filtrar por rangos
    if (this.state.operator === RANGE) {
      const firstNumber = parseInt(this.state.value, 10);
      const secondNumber = parseInt(this.state.secondValue, 10);

      if (!this.state.value && !this.state.secondValue) {
        return true;
      }

      if (this.state.value && !this.state.secondValue) {
        return currentItem[attribute] >= firstNumber;
      }

      if (!this.state.value && this.state.secondValue) {
        return currentItem[attribute] <= secondNumber;
      }

      if (this.state.value && this.state.secondValue) {
        return currentItem[attribute] >= firstNumber && currentItem[attribute] <= secondNumber;
      }
    }

    // otras opciones de filtrado
    if (!this.state.value) { return true; }
    const numericValue = parseInt(this.state.value, 10);

    switch (this.state.operator) {
      case EQUALS_TO:
        return currentItem[attribute] === numericValue;
      case LESS_THAN:
        return currentItem[attribute] < numericValue;
      case GREATER_THAN:
        return currentItem[attribute] > numericValue;
      default:
        return true;
    }
  }


  // -------------------------------
  // ------- render methods --------
  // -------------------------------
  renderInputs() {
    const { operator } = this.state;

    if (operator === RANGE) {
      return (
        <Row columns={2} style={{ paddingTop: 0 }}>
          <Column>
            <p style={{ marginBottom: 3 }}>Desde:</p>

            <Input
              fluid
              value={this.state.value}
              onChange={this.onInputChange.bind(this)}
              placeholder="Teclee un criterio"
            />
          </Column>

          <Column>
            <p style={{ marginBottom: 3 }}>Hasta:</p>

            <Input
              fluid
              value={this.state.secondValue}
              onChange={this.onSecondInputChange.bind(this)}
              placeholder="Teclee un criterio"
            />
          </Column>
        </Row>
      );
    }

    return (
      <Row style={{ paddingTop: 0 }}>
        <Column>
          <Input
            fluid
            value={this.state.value}
            onChange={this.onInputChange.bind(this)}
            placeholder="Teclee un criterio de busqueda"
          />
        </Column>
      </Row>
    );
  }

  render() {
    const filtering = this.state.value || (this.state.operator === RANGE && this.state.secondValue);
    const filterIconColor = filtering ? '#e40000' : 'black';

    const options = [
      {
        text: 'Igual a',
        value: EQUALS_TO,
      },
      {
        text: 'Menor que',
        value: LESS_THAN,
      },
      {
        text: 'Mayor que',
        value: GREATER_THAN,
      },
      {
        text: 'Por rango',
        value: RANGE,
      },
    ];

    const triggerButton = (
      <Icon name="filter" style={{ marginRight: 5, color: filterIconColor, cursor: 'pointer' }} />
    );

    const popUpContent = (
      <Grid
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <Row>
          <Column width={9} verticalAlign="middle">
            <span style={{ marginRight: 5 }}>
              Filtrar por {this.props.label}:
            </span>
          </Column>

          <Column width={7} verticalAlign="middle">
            <Dropdown
              fluid
              selection
              value={this.state.operator}
              options={options}
              onChange={(event, data) => { this.setState({ operator: data.value }); }}
            />
          </Column>
        </Row>

        { this.renderInputs() }
      </Grid>
    );

    return (
      <div style={{ display: 'inline-block', marginRight: 5 }}>
        <Popup
          flowing
          hoverable
          on="click"
          position="bottom center"
          trigger={triggerButton}
          content={popUpContent}
          open={this.state.popUpVisible}
          onOpen={this.onPopUpOpen.bind(this)}
          onClose={this.onPopUpClose.bind(this)}
          style={{ maxWidth: 420 }}
        />

        <span
          style={{ cursor: this.props.data.attribute ? 'pointer' : 'default' }}
          onClick={() => { this.props.onHeaderClick(this.props.data); }}
        >
          { this.props.label }
        </span>
      </div>
    );
  }
}


NumberFilter.propTypes = {
  label: PropTypes.string,
  attribute: PropTypes.string,
  filterRequest: PropTypes.func,
  updateFilterFunction: PropTypes.func,
  onHeaderClick: PropTypes.func,
  data: PropTypes.object,
};


export default NumberFilter;
