import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import formatter from "../utils/formatter";
import { ORANGE } from "../colors";

class ExpansibleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentVisible: false,
      contentHeight: 0,
    };
  }

  contentRef = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return (
      !isEqual(nextState, this.state) ||
      !isEqual(clean(nextProps), clean(this.props))
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.contentVisible && this.state.contentVisible) {
      this.calcHeight();
    }

    if (prevState.contentVisible && !this.state.contentVisible) {
      this.setState({ contentHeight: 0 });
    }
  }

  toogleTransition() {
    this.setState({ contentVisible: !this.state.contentVisible });
  }

  calcHeight() {
    const contentHeight = this.contentRef.current?.firstChild.offsetHeight;
    this.setState({ contentHeight });
  }

  render() {
    const { contentHeight, contentVisible } = this.state;
    const { style, trigger, children } = this.props;

    let boxShadow;

    let contentStyle = {
      transition: "all 500ms ease",
      height: contentHeight,
      // overflow: "hidden",
    };

    if (contentVisible) {
      contentStyle.marginBottom = 50;
      boxShadow = "0 2px 7px rgb(0,0,0,0.15)";
    }

    return (
      <div style={{ ...style, overflow: "hidden", cursor: "pointer",  backgroundColor: "white",
      border: "3px solid rgb(226, 212, 188)",
      borderRadius: 10,
      paddingLeft: 15,
      paddingTop: 5,
      paddingBottom: 5,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      marginBottom: 10,
      boxShadow, }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: contentVisible && ORANGE
          }}
          onClick={this.toogleTransition.bind(this)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {trigger}
          </div>

          <div style={{ padding: 10 }}>
            <Icon name={contentVisible ? "minus" : "add"} />
          </div>
        </div>

        <div ref={this.contentRef} style={contentStyle}>
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

ExpansibleContainer.propTypes = {
  style: PropTypes.object,
  trigger: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export { ExpansibleContainer };
