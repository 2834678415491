import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as calls from './calls';


export function* subscribeUser(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subscribe', value: true } });

    const request = calls.subscribeUser({ email: action.payload });
    const response = yield call(request);
    const serverResponse = response.data;

    eventManager.emit('subscribe_response', serverResponse);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subscribe', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subscribe', value: false } });
  }
}

export function* registerInWebinar(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'registerWebinar', value: true } });

    const request = calls.registerInWebinar(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    eventManager.emit('registerWebinar_response', serverResponse);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'registerWebinar', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'registerWebinar', value: false } });
  }
}


export function* getPosts(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: true } });

    const request = calls.getPosts(action.payload);
    const response = yield call(request);
    const headers = response.headers;
    const serverResponse = response.data;

    const postsPayload = {
      totalPages: parseInt(headers['x-wp-totalpages']),
      posts: serverResponse
    };

    yield put({ type: actionsTypes.SET_POSTS, payload: postsPayload });
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: false } });
  }
}

export function* getPostDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'post_detail', value: true } });

    const request = calls.getPostDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    yield put({ type: actionsTypes.SET_POST_DETAIL, payload: serverResponse.length ? serverResponse[0] : null });
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'post_detail', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'post_detail', value: false } });
  }
}

export function* getTags(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tags', value: true } });

    const request = calls.getTags();
    const response = yield call(request);
    const serverResponse = response.data;

    yield put({ type: actionsTypes.SET_TAGS, payload: serverResponse });
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tags', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tags', value: false } });
  }
}

export function* getPostsByName(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: true } });

    const request = calls.getPostsByName(action.payload);
    const response = yield call(request);
    const headers = response.headers;
    const serverResponse = response.data;

    const postsPayload = {
      totalPages: headers['x-wp-totalpages'],
      posts: serverResponse
    };

    // console.log(response)
    // console.log('numero total de paginas: ', headers['x-wp-totalpages']);

    yield put({ type: actionsTypes.SET_POSTS_BYNAME, payload: postsPayload });
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: false } });
  } catch (error) {
    notifications.error(typeof error === 'string' ? error : error.message);
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'posts', value: false } });
  }
}
