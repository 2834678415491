import * as actionsTypes from './types';


export const setLanguage = (params) => ({
  type: actionsTypes.SET_LANGUAGE,
  payload: params,
});

export const setMobile = (params) => ({
  type: actionsTypes.SET_MOBILE,
  payload: params,
});
