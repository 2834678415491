import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Label, Image } from 'semantic-ui-react';
import _ from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';
import i18n from '../utils/i18n.json';
import IMG_SEE_ON from '../assets/seeOn.png';
import IMG_SEE_OFF from '../assets/seeOff.png';

const { Row, Column } = Grid;
const { errorRequired } = i18n.text;


class TextInput extends Component {
  constructor(props) {
    super(props);
    let valid;

    if (props.defaultValue) {
      valid = true;
    } else {
      valid = !props.required;
    }

    this.state = {
      value: props.defaultValue ? props.defaultValue : '',
      valid,
      message: props.required ? errorRequired[props.language].replace('#label',props.label.toLowerCase()) : '',
      dirty: false,
      errorVisible: false,
      seePassword: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue) {
      this.setState({ value: nextProps.defaultValue, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !_.isEqual(nextState, this.state) || !_.isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    this.setState({ errorVisible: false });

    if (this.props.required && !e.target.value) {
      this.setState({
        valid: false,
        dirty: true,
        value: '',
        message: errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()),
        errorVisible: this.props.showErrors,
      });
    } else {
      const regex = /[^A-ZÑ a-zñ0-9]/g;
      let matchRegex = false;

      if (this.props.alphanumeric && !regex.test(e.target.value)) {
        matchRegex = true;
      } else if (!this.props.alphanumeric) {
        matchRegex = true;
      }

      if (matchRegex && this.props.max && e.target.value.length <= this.props.max) {
        this.setValue(e.target.value);
      } else if (matchRegex && !this.props.max) {
        this.setValue(e.target.value);
      }
    }
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value;
  }

  setValue(value) {
    let valueData;
    this.setState({ dirty: true });

    // custom validations
    if (this.props.validator) {
      valueData = this.props.validator(value);

      this.setState({
        value,
        valid: valueData.valid,
        message: valueData.message ? valueData.message : '',
      });
    } else {
      this.setState({ value, valid: true, message: '' });
    }
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    let valid;

    if (this.props.defaultValue) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : '',
      valid,
      message: this.props.required ? errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()) : '',
      dirty: false,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderInput() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };
    let styleInputInside = {};

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
      styleInputInside = { ...styles.errorInputInside };
    }

    if (this.props.password) {
      const IMG_SEE = this.state.seePassword ? IMG_SEE_ON : IMG_SEE_OFF;
      const typeString = this.state.seePassword ? 'text' : 'password';

      return (
        <Grid textAlign='right' style={{ padding: 14 }}>
          <Row style={{ border: '1px solid #DEDEDF', padding: 0, borderRadius: 4, ...finalInputStyle }}>
            <Column width={13} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <input
                type={typeString}
                value={this.state.value}
                name={this.props.name}
                className={className}
                onChange={this.onChange.bind(this)}
                disabled={this.props.readOnly}
                placeholder={this.props.placeholder}
                onKeyUp={this.props.onKeyUp}
                style={{ border: 'none', ...styleInputInside }}
              />
            </Column>

            <Column
              width={3}
              style={{ paddingRight: 0, paddingTop: 10, ...styleInputInside }}
              onClick={() => this.setState({ seePassword: !this.state.seePassword })}
            >
              <Image src={IMG_SEE} style={{ width: 30, cursor: 'pointer' }}/>
            </Column>
          </Row>
        </Grid>
      );
    }

    return (
      <input
        type={this.props.password ? 'password' : 'text'}
        value={this.state.value}
        name={this.props.name}
        className={className}
        onChange={this.onChange.bind(this)}
        disabled={this.props.readOnly}
        placeholder={this.props.placeholder}
        onKeyUp={this.props.onKeyUp}
        style={finalInputStyle}
      />
    );
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
    }

    const labelString = this.props.required ? `${this.props.label} *` : this.props.label;

    // render input
    if (this.props.inlineLabel) {
      return (
        <Form.Field>
          <Grid>
            <Column width={3} textAlign="right" verticalAlign="middle">
              <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
                { labelString }:
              </label>
            </Column>

            <Column width={13}>
              { this.renderInput() }
              { this.renderErrorLabel() }
            </Column>
          </Grid>
        </Form.Field>
      );
    }

    return (
      <Form.Field>
        <label style={finalLabelStyle}>
          { labelString }
        </label>

        { this.renderInput() }
        { this.renderErrorLabel() }
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  errorInputInside: {
    background: 'rgba(224, 180, 180, 0.48)',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


TextInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  password: PropTypes.bool,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  showErrors: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number]
  ),
  inlineLabel: PropTypes.bool,
  validator: PropTypes.func,
  alphanumeric: PropTypes.bool,
  max: PropTypes.number,
  setFormData: PropTypes.func.isRequired,
  language: PropTypes.string,
  onKeyUp: PropTypes.func,
};


// exportar componente
export default TextInput;
