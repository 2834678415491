import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Form } from "semantic-ui-react";
import _ from "lodash";
import formatter from "../../utils/formatter";
import TextInput from "./inputs/TextInput";
import TextAreaInput from "./inputs/TextAreaInput";
import EmailInput from "./inputs/EmailInput";
import NumericInput from "./inputs/NumericInput";
import DropdownInput from "./inputs/DropdownInput";
import ConfirmPasswordInput from "./inputs/ConfirmPasswordInput";
import CheckboxInput from "./inputs/CheckboxInput";
import RadioInput from "./inputs/RadioInput";
import HourInput from "./inputs/HourInput";
import DateInput from "./inputs/DateInput";
import FileInput from "./inputs/FileInput";
import CreditCardInput from "./inputs/CreditCardInput";
import { FadeInView } from "../FadeInView";

const { Column } = Grid;

class AutoForm extends Component {
  constructor(props) {
    super(props);
    this.formInputs = {}; // form's registered inputs

    this.state = {
      formData: {},
    };
  }

  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return (
      !_.isEqual(nextState, this.state) ||
      !_.isEqual(clean(nextProps), clean(this.props))
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onChange &&
      !_.isEqual(prevState.formData, this.state.formData)
    ) {
      this.props.onChange(this.formatFormData());
    }
  }

  // --------------------
  // --- form methods ---
  // --------------------
  setFormData(inputName, data) {
    const formData = { ...this.state.formData };
    formData[inputName] = data;
    this.setState({ formData });
  }

  getData() {
    this.dirtFormInputs();
    const validForm = this.validForm();

    if (validForm.valid) {
      return { IsValid: true, Data: this.formatFormData() };
    }

    return { IsValid: false, Message: validForm.message };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.dirtFormInputs();

    if (this.validForm().valid && this.props.onSubmit) {
      this.props.onSubmit(this.formatFormData());
    }
  }

  validForm() {
    const result = { valid: true, message: "" };

    // iterate over inputs
    Object.keys(this.formInputs).forEach((input) => {
      if (this.formInputs[input]) {
        const currentInput = this.formInputs[input];

        if (result.valid && !currentInput.state.valid) {
          result.valid = false;
          result.message = currentInput.state.message;

          if (this.props.onError) {
            this.props.onError(currentInput.state.message);
          }

          if (this.props.showErrors && currentInput.showError) {
            currentInput.showError();
          }
        }
      }
    });

    return result;
  }

  formatFormData() {
    const formattedData = {};

    // iterate over inputs
    Object.keys(this.formInputs).forEach((input) => {
      const currentInput = this.formInputs[input];

      if (currentInput !== null) {
        formattedData[input] = currentInput.getValue();
      }
    });

    return formattedData;
  }

  dirtFormInputs() {
    Object.keys(this.formInputs).forEach((input) => {
      if (this.formInputs[input]) {
        this.formInputs[input].dirtInput();
      }
    });
  }

  reset() {
    Object.keys(this.formInputs).forEach((input) => {
      if (this.formInputs[input]) {
        this.formInputs[input].resetInput();
      }
    });
  }

  // ----------------------
  // --- render methods ---
  // ----------------------
  renderInputs() {
    return this.props.inputs.map((inputSettings, index) => {
      let inputComponent;

      if (
        inputSettings.active !== undefined &&
        inputSettings.active === false
      ) {
        return null;
      }

      const inputProps = {
        ...inputSettings,
        showErrors: this.props.showErrors,
        setFormData: this.setFormData.bind(this),
        language: this.props.language || "en",
      };

      switch (inputSettings.type) {
        case "text":
          inputComponent = (
            <TextInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "textArea":
          inputComponent = (
            <TextAreaInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "number":
          inputComponent = (
            <NumericInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "email":
          inputComponent = (
            <EmailInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "dropdown":
          inputComponent = (
            <DropdownInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "confirmPassword":
          inputComponent = (
            <ConfirmPasswordInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "checkbox":
          inputComponent = (
            <CheckboxInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "radio":
          inputComponent = (
            <RadioInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "hour":
          inputComponent = (
            <HourInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "date":
          inputComponent = (
            <DateInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "file":
          inputComponent = (
            <FileInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "credit-card":
          inputComponent = (
            <CreditCardInput
              {...inputProps}
              ref={(refInput) => {
                this.formInputs[inputSettings.name] = refInput;
              }}
            />
          );
          break;
        case "container":
          inputComponent = inputSettings.content;
          break;
        default:
          throw new Error(
            `Tipo de input "${inputSettings.type}" no soportado por Autoform. Inputs disponibles: "text", "textArea", "email", "number", "dropdown", "confirmPassword", "checkbox", "radio", "hour", "date", "container", "file", "file-option", "file-rocket", "credit-card"`
          );
      }

      let input = inputComponent;

      if (this.props.animated) {
        input = <FadeInView delay={70 * index}>{inputComponent}</FadeInView>;
      }

      return (
        <Column
          textAlign={inputSettings.textAlign ? inputSettings.textAlign : null}
          key={inputSettings.name || index}
          width={
            inputSettings.type !== "confirmPassword" ? inputSettings.width : 16
          }
          verticalAlign="middle"
        >
          {input}
        </Column>
      );
    });
  }

  render() {
    const { stackableDisabled } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit.bind(this)}
        loading={this.props.loading}
        style={this.props.style}
      >
        <Grid stackable={!stackableDisabled}>{this.renderInputs()}</Grid>

        {this.props.children}
      </Form>
    );
  }
}

AutoForm.propTypes = {
  animated: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object,
  showErrors: PropTypes.bool,
  language: PropTypes.string,
  stackableDisabled: PropTypes.bool,
};

export { AutoForm };
