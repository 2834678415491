import React, { Component } from 'react';
import PropTypes from 'prop-types';


class HoverableLink extends Component {
  state = {
    hovering: false,
  }

  onClick(event) {
    const { onClick } = this.props;
    event.preventDefault();

    if (onClick) {
      onClick();
    }
  }

  render() {
    const { hovering } = this.state;
    const { children, url, style = {}, hoveredStyle = {}} = this.props;

    return (
      <a
        href={url}
        style={hovering ? hoveredStyle : style}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        onClick={this.onClick.bind(this)}
      >
        { children }
      </a>
    );
  }
}


HoverableLink.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  url: PropTypes.string.isRequired,
  hoveredStyle: PropTypes.object
};


export { HoverableLink };
