export const START_VIEW_TRANSITION = 'start-view-transition';
export const SHOW_NOTIFICATION = 'show-notification';
export const API_URL = 'https://gensoil.net';
export const WORDPRESS_API_URL = 'https://gensoil.net/wordpress/index.php?rest_route=/wp/v2';
export const MOBILE_BREAKPOINT = 768;
export const COOKIES_ACCEPTED = 'cookies-accepted';
export const SESSION_KEY = 'gensoil-session';
export const EMEAT_DEVICE_TOKEN = 'emeat-device-token';
export const EMEAT_SESSION_KEY = 'emeat-session';
export const IFRAME_FORM_TEXAS_URL = 'https://emeat-price-indices.com/texas';
export const IFRAME_FORM_LOS_ANGELES_URL = 'https://emeat-price-indices.com/los-angeles';
