import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import transitions from '../../utils/transitions';


const DARK_TEXT_COLOR = '#FFFFFF';
const DARK_COLOR = '#7798BF';
const BORDER_HEIGHT = 3;
const SHADOW_HEIGHT = 2;
const ROTATION = 20;


class LightBlackButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      borderBottom: BORDER_HEIGHT,
      borderTop: 0,
      shadowOffset: SHADOW_HEIGHT,
      degreesRotated: ROTATION,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.hovering && this.state.hovering) {
      this.pressAnimation();
    }

    if (prevState.hovering && !this.state.hovering) {
      this.leaveAnimation();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  // -------------------------
  // -------  methods --------
  // -------------------------
  pressAnimation() {
    const animationID = Math.random().toString(36).slice(-5);
    this.animationID = animationID;

    const borderParams = {
      initialValue: this.state.borderBottom,
      finalValue: 0,
      duration: 300,
      sampling: 50,
    };

    const shadowParams = {
      initialValue: this.state.shadowOffset,
      finalValue: 0,
      duration: 300,
      sampling: 50,
    };

    const rotationParams = {
      initialValue: this.state.degreesRotated,
      finalValue: 0,
      duration: 300,
      sampling: 50,
    };

    transitions.easePolyOut(borderParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({
          borderBottom: response.value,
          borderTop: BORDER_HEIGHT - response.value,
        });
      }
    });

    transitions.easePolyOut(shadowParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({ shadowOffset: response.value });
      }
    });

    transitions.easePolyOut(rotationParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({ degreesRotated: response.value });
      }
    });
  }

  leaveAnimation() {
    const animationID = Math.random().toString(36).slice(-5);
    this.animationID = animationID;

    const borderParams = {
      initialValue: this.state.borderBottom,
      finalValue: BORDER_HEIGHT,
      duration: 300,
      sampling: 50,
    };

    const shadowParams = {
      initialValue: this.state.shadowOffset,
      finalValue: SHADOW_HEIGHT,
      duration: 300,
      sampling: 50,
    };

    const rotationParams = {
      initialValue: this.state.degreesRotated,
      finalValue: ROTATION,
      duration: 300,
      sampling: 50,
    };

    transitions.easePolyOut(borderParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({
          borderBottom: response.value,
          borderTop: BORDER_HEIGHT - response.value,
        });
      }
    });

    transitions.easePolyOut(shadowParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({ shadowOffset: response.value });
      }
    });

    transitions.easePolyOut(rotationParams, (response) => {
      if (!this.unmounted && animationID === this.animationID) {
        this.setState({ degreesRotated: response.value });
      }
    });
  }


  // -------------------------------
  // ------- render methods --------
  // -------------------------------
  render() {
    return (
      <Button
        type={this.props.type ? this.props.type : 'button'}
        style={this.props.disabled ? { background: '#C5C5C5', color: '#717885', ...this.props.style } : { background: DARK_COLOR, color: DARK_TEXT_COLOR, ...this.props.style }}
        onClick={this.props.onClick}
        loading={this.props.loading}
        fluid={this.props.fluid}
        disabled={this.props.disabled}
      >
        { this.props.label || this.props.children }
      </Button>
    );
  }
}


LightBlackButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  fluid: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};


export { LightBlackButton };
