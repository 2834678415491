import * as actionsTypes from './types';


const INITIAL_STATE = {
  headerVisible: true,
  selectedView: '',
  session: null,
  totalPages: null,
  tags: [],
  posts: [],
  postDetail: null,
  loadings: {
    tags: false,
    subscribe: false,
    posts: false,
    post_detail: false,
    registerWebinar: false
  },
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.SET_HEADER_VISIBILITY:
      return { ...state, headerVisible: action.payload };
    case actionsTypes.SET_USER_SESSION:
      return { ...state, session: action.payload };
    case actionsTypes.SET_SELECTED_VIEW:
      return { ...state, selectedView: action.payload };
    case actionsTypes.SET_POSTS:
      return { ...state, posts: action.payload.posts, totalPages: action.payload.totalPages };
    case actionsTypes.SET_POST_DETAIL:
      return { ...state, postDetail: action.payload };
    case actionsTypes.SET_TAGS:
      return { ...state, tags: action.payload };
    case actionsTypes.SET_POSTS_BYNAME:
      return { ...state, postsByName: action.payload.posts, totalPages: action.payload.totalPages };
    case actionsTypes.SET_LOADING:
      return setLoading(state, action);
    default:
      return state;
  }
};
