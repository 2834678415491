import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Grid, Icon, Image } from 'semantic-ui-react';
import * as CarouselLib from 'react-responsive-carousel';
import formatter from '../../../../utils/formatter';
import logoImage from '../../../../assets/text-logo.png';
import { WHITE, DARK_BLACK, RED, BLUE } from '../../../../colors';

const { Row, Column } = Grid;

const styles = {
  headerContainer: {
    background: DARK_BLACK,
    boxShadow: '0 3px 3px rgb(0,0,0,0.2)',
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%'
  },
  menuButton: {
    color: 'white',
    fontWeight: 'bold',
    padding: 10,
    borderRadius: 3,
    transition: 'all 300ms ease 0s',
  },
  hoveredMenuButton: {
    color: RED,
  },
};


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverOption: null,
      mobileMenuVisible: false,
    };
  }

  componentDidMount(){
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }


  // -------------------
  // ----- methods -----
  // --------------------
  onOptionClick(option) {
    if (option.url !== undefined) {
      this.props.navigate(option.url);
    }
  }

  onMainLogoClick() {
    this.setState({ mobileMenuVisible: false });
    this.props.navigate('/');
  }

  onSidebarButtonClick() {
    const { mobileMenuVisible } = this.state;
    this.setState({ mobileMenuVisible: !mobileMenuVisible });
  }

  getHeaderClass() {
    const { headerVisible } = this.props;
    return headerVisible ? 'visible-header' : 'hidden-header';
  }


  // --------------------------
  // ----- render methods -----
  // --------------------------
  renderNews() {
    const posts = this.props.posts;


    if (!posts || !posts.length) {
      return;
    }

    return posts.map((currentItem, index) =>
      <div key={index} style={{color: WHITE,   backgroundColor: BLUE, display: 'flex', justifyContent: 'center' }}>
        <div className='upper-banner' style={{ fontWeight: 'bold', fontSize: 16 }} dangerouslySetInnerHTML={{
          __html:
            currentItem.content.rendered
        }} />
      </div>
    );
  }

  renderUpperBanner() {
    const { scrollPosition, mobile, selectedView, loadings } = this.props;

    if (mobile) {
      return null;
    }

    if (loadings.posts) {
      return
    }

    if (selectedView === 'home') {
      const banenrStyle = {
        overflow: 'hidden',
        transition: 'all 300ms ease',
        background: '#7798BF',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        padding: 0,
      };

      if (scrollPosition) {
        banenrStyle.height = 0;
      }

      return (
        <Row style={banenrStyle}>
          <Grid container>
            <Column>
              <div style={{ position: 'absolute', top: 'calc(50% - 10px)', right: 0, zIndex: 1000 }}>
                <Icon
                  name="chevron right"
                  onClick={() => { this.Carousel.onClickNext(); }}
                  style={{ fontSize: 20, color: WHITE, cursor: 'pointer' }}
                />
              </div>

              <CarouselLib.Carousel
                ref={(ref) => { this.Carousel = ref; }}
                infiniteLoop
                autoPlay
                width={'100%'}
                showThumbs={false}
                showStatus={false}
                interval={7000}
                showIndicators={false}
                dynamicHeight
                renderArrowNext={() => <div />}
                renderArrowPrev={() => <div />}
              >
                { this.renderNews() }
              </CarouselLib.Carousel>

              <div style={{ position: 'absolute', top: 'calc(50% - 10px)', left: 0, zIndex: 1000 }}>
                <Icon
                  name="chevron left"
                  onClick={() => { this.Carousel.onClickPrev(); }}
                  style={{ fontSize: 20, color: WHITE, cursor: 'pointer' }}
                />
              </div>
            </Column>
          </Grid>
        </Row>
      );
    }

    return null;
  }

  renderDesktopHeader() {
    return (
      <Row only="tablet computer">
        <Column width={16} style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src={logoImage}
            style={{ width: 115, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
          />
        </Column>
      </Row>
    );
  }

  renderMobileHeader() {
    return (
      <Row only="mobile" style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Column>
          <Grid columns={2}>
            <Column width={16} style={{ height: 88, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image
                src={logoImage}
                onClick={this.onMainLogoClick.bind(this)}
                style={{ width: 115, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
              />
            </Column>
          </Grid>
        </Column>
      </Row>
    );
  }

  render() {
    return (
      <Grid id="main-header" className={this.getHeaderClass()} style={styles.headerContainer}>
        { this.renderUpperBanner() }

        <Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Column style={{ padding: 0 }}>
            <Grid container>
              { this.renderMobileHeader() }
              { this.renderDesktopHeader() }
            </Grid>
          </Column>
        </Row>
      </Grid>
    );
  }
}


Header.propTypes = {
  navigate: PropTypes.func,
  headerVisible: PropTypes.bool,
  selectedView: PropTypes.string,
  scrollPosition: PropTypes.number,
  mobile: PropTypes.bool,
  posts:  PropTypes.array,
  loadings: PropTypes.object,
};


const mapStateToProps = (state) => ({
  headerVisible: state.web.headerVisible,
  language: state.app.language,
  selectedView: state.web.selectedView,
  posts: state.web.posts,
  loadings: state.web.loadings
});


export default connect(mapStateToProps)(Header);
