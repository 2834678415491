import { SHOW_NOTIFICATION } from './constants';
import eventManager from './eventManager';


export default {
  success(message) {
    eventManager.emit(SHOW_NOTIFICATION, { type: 'success', message });
  },
  error(message) {
    eventManager.emit(SHOW_NOTIFICATION, { type: 'error', message });
  },
  warning(message) {
    eventManager.emit(SHOW_NOTIFICATION, { type: 'warning', message });
  },
  info(message) {
    eventManager.emit(SHOW_NOTIFICATION, { type: 'info', message });
  },
  show(title, text) {
    const options = {
      body: text,
      requireInteraction: true,
    };

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification'); // eslint-disable-line
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const notification = new Notification(title, options); // eslint-disable-line no-unused-vars
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(title, options); // eslint-disable-line no-unused-vars
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  },
  requestPermission() {
    if (Notification.permission !== 'denied') {
      // eslint-disable-next-line no-unused-vars
      Notification.requestPermission((permission) => {
        // do not do anything else at this moment
      });
    }
  },
};
