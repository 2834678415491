import axios from 'axios';
import { API_URL, WORDPRESS_API_URL } from '../../../utils/constants';


export function subscribeUser(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/users/subscribe`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function registerInWebinar(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/api/users/register-webinar`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}


export function getTags(page) {
  return () => axios({
    method: 'get',
    url: `${WORDPRESS_API_URL}/tags&per_page=30`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getPosts(page) {
  return () => axios({
    method: 'get',
    url: `${WORDPRESS_API_URL}/posts&page=${page}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getPostDetail(slug) {
  return () => axios({
    method: 'get',
    url: `${WORDPRESS_API_URL}/posts&slug=${slug}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function getPostsByName(name) {
  return () => axios({
    method: 'get',
    url: `${WORDPRESS_API_URL}/search?=${name}&subtype=articulos`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
