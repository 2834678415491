import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IFRAME_FORM_TEXAS_URL, IFRAME_FORM_LOS_ANGELES_URL } from '../../../../utils/constants';


class IFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iFrameHeight: this.props.mobile ? 11800 : 3050,
    };
  }

  render() {
    const { selectedTab } = this.props;

    return (
      <iframe
        style={{ border: 0, height: this.state.iFrameHeight }}
        id="inlineFrameExample"
        title="Inline Frame Example"
        src={selectedTab === 0 ? IFRAME_FORM_TEXAS_URL : IFRAME_FORM_LOS_ANGELES_URL}>
      </iframe>
    );
  }
}


IFrame.propTypes = {
  mobile: PropTypes.bool,
  selectedTab: PropTypes.number
};


const mapStateToProps = (state) => ({
  loading: state.web.loadings
});


export default connect(mapStateToProps)(IFrame);
