import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react';
import { Helmet } from "react-helmet";
import { Parallax } from "react-parallax";
import { FadeInOutView } from '../../../../components';
import translate from '../../../../utils/i18n/contact.json';
import { WHITE, GRAY, CONTAINERS_BOX_SHADOW, RED } from '../../../../colors';
import FondoSlogan from '../../../../assets/FondoSlogan.png'
import beefImg from '../../../../assets/beef.png';
import porkImg from '../../../../assets/pork.png';
import IFrame from './Iframe';
import pricesNotAvailable from '../../../../assets/prices-not-available.png';
import pricesClosed from '../../../../assets/prices-is-closed.png';
import eventManager from '../../../../utils/eventManager';

const { Row, Column } = Grid;
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const days = ["Lunes", "Martes", "Miércoles","Jueves", "Viernes", "Sábado", "Domingo"];
const DAY = 1000 * 60 * 60 * 24; // 24 hours

const styles = {
  slogan: {
    fontSize: 40,
    color:  WHITE,
    marginTop: 180,
    textAlign: 'right',
    fontFamily: 'bebas-neue'
  },
  sloganMobile: {
    fontSize: 16,
    marginTop: 30,
    color:  WHITE,
    textAlign: 'center',
  },
  title: {
    fontSize: 25,
    marginTop: 18,
    marginBottom: 20,
    marginLeft: 30,
    boxShadow: '10px 5px 5px black'
  },
  container: {
    boxShadow: CONTAINERS_BOX_SHADOW,
    borderRadius: 10,
    backgroundColor: WHITE,
    textAlign: 'left',
    marginTop: 30,
    marginBottom: 30,
    padding: 30
  },
  containerMobile: {
    boxShadow: CONTAINERS_BOX_SHADOW,
    borderRadius: 5,
    backgroundColor: WHITE,
    textAlign: 'center',
    marginTop: -30,
    padding: 30
  },
  labels: {
    fontSize: 15,
    color: 'rgb(80,80,80)',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  inputs: {
    color: GRAY,
    border: '1px solid rgb(200,200,200)'
  },
  itemMenu: {
    transition: 'all 250ms ease',
    borderBottom: '2px solid #ececec',
    height: '60px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 15px',
    color: GRAY,
    fontSize: 17,
    cursor: 'pointer'
  },
  itemMenuSelected: {
    transition: 'all 250ms ease',
    borderBottom: '4px solid #7798BF',
    height: '60px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 15px',
    color: '#1D1A3E',
    fontWeight: 'bold',
    fontSize: 17,
    cursor: 'pointer'
  },
};

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedTab: 0 };
  }

  getLastSunday() {
    const currentDate = new Date();
    return new Date(currentDate.getTime() - (currentDate.getDay() * DAY));
  }

  getNextSaturday() {
    const currentDate = new Date();
    const diference = Math.abs(currentDate.getDay() - 6);
    return new Date(currentDate.getTime() + (diference * DAY));
  }

  getNextWednesday() {
    const currentDate = new Date();
    const diference = Math.abs(currentDate.getDay() - 3);
    return new Date(currentDate.getTime() + (diference * DAY));
  }

  changeTab(selectedTab) {
    eventManager.emit('change-tab', () => {
      this.setState({ selectedTab });
    });
  }

  renderBanner() {
    const { language, mobile } = this.props;

    if (mobile) {
      return (
        <div>
          <Grid>
            <Column style={{ padding: '3px 0' }}>
              <Parallax bgImage={FondoSlogan} bgImageStyle={{ width: null, height: 270 }} strength={200}>
                <div style={{ height: 220 }}>
                  <Grid container>
                    <Row centered textAlign='right'>
                      <Column width={16} style={styles.sloganMobile}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }} >
                          <Image src={beefImg} size='mini' avatar style={{ marginRight: 5 }}/>
                          <Image src={porkImg} size='mini' avatar style={{ marginLeft: 5 }}/>
                        </div>
                        <p style={{ borderBottom: `2px solid ${RED}`, margin: '0 5%', fontSize: 15 }}>
                          { translate.slogan[language] }
                        </p>
                      </Column>
                    </Row>

                    <Row centered style={{ paddingTop: 0 }}>
                      <Column
                        width={12}
                        style={{
                          padding: '10px 0',
                          fontSize: 40,
                          paddingTop: 0,
                          color:  WHITE,
                          textAlign: 'center',
                          lineHeight: 1,
                          fontFamily: 'bebas-neue'
                        }}
                      >
                        PRECIOS MEXICANOS
                      </Column>
                    </Row>
                  </Grid>
                </div>
              </Parallax>
            </Column>
          </Grid>
        </div>
      );
    }

    return (
      <div>
        <Grid>
          <Column style={{ padding: '3px 0' }}>
            <Parallax bgImage={FondoSlogan} bgImageStyle={{ width: '100%', height: 280 }} strength={200}>
              <div style={{ height: 250 }}>
                <Grid container>
                  <Row centered style={{ marginTop: 30 }}>
                    <Column
                      width={16}
                      style={{
                        padding: '10px 0',
                        fontSize: 20,
                        fontWeight: 'bold',
                        color:  WHITE,
                        textAlign: 'center',
                        paddingBottom: 0
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }} >
                        <Image src={beefImg} size='mini' avatar style={{ marginRight: 5 }}/>
                        <Image src={porkImg} size='mini' avatar style={{ marginLeft: 5 }}/>
                      </div>
                      <p style={{ borderBottom: `2px solid ${RED}`, margin: '0 26%' }}>
                        { translate.slogan[language] }
                      </p>
                    </Column>
                  </Row>

                  <Row centered style={{ paddingTop: 0 }}>
                    <Column
                      width={7}
                      style={{
                        padding: '10px 0',
                        fontSize: 60,
                        color:  WHITE,
                        textAlign: 'center',
                        fontFamily: 'bebas-neue'
                      }}
                    >
                      PRECIOS MEXICANOS
                    </Column>
                  </Row>
                </Grid>
              </div>
            </Parallax>
          </Column>
        </Grid>
      </div>
    );
  }

  renderMessage(day) {
    const { mobile } = this.props;
    const hasPassed = day > 4;
    let srcImage;
    let text;
    let secondText;

    if (hasPassed) {
      srcImage = pricesClosed;
      text =  'La captura de precios para esta semana';
      secondText = 'ya no se encuentra disponible';
    } else {
      const nextDate = this.getNextWednesday();
      srcImage = pricesNotAvailable;
      text = 'La captura de precios estará disponible el día';
      secondText = `${days[nextDate.getDay()-1]} ${nextDate.getDate()} de ${months[nextDate.getMonth()]}`;
    }

    if (mobile) {
      return (
        <div style={{ position:'relative', marginTop: 50 }}>
          <Grid container centered stackable>
            <Row centered>
              <Column width={12} style={styles.container}>
                <Grid>
                  <Row>
                    <Column style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                      <Image src={srcImage} size='mini' avatar style={{ marginRight: 5 }}/>
                    </Column>
                  </Row>

                  <Row>
                    <Column style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                      <span style={{ fontSize: 16, fontFamily: 'Helvetica', color: GRAY }}>{ text } <span style={{fontWeight: 'bold'}}>{ secondText }</span></span>
                    </Column>
                  </Row>
                </Grid>
              </Column>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div style={{ position:'relative', marginTop: 30 }}>
        <Grid container centered stackable>
          <Row centered>
            <Column width={12} style={styles.container}>
              <Grid>
                <Row>
                  <Column style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={srcImage} size='tiny' avatar style={{ alignSelf: 'center' }} />
                  </Column>
                </Row>

                <Row>
                  <Column style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ color: GRAY, fontSize: 16, fontFamily: 'Helvetica', textAlign: 'center' }}>
                      { text } <span style={{ fontWeight: 'bold' }}>{ secondText }</span>
                    </span>
                  </Column>
                </Row>
              </Grid>
            </Column>
          </Row>
        </Grid>
      </div>
    );
  }

  renderNotes() {
    const { selectedTab } = this.state;

    if (selectedTab === 0) {
      return (
        <div style={{ fontSize: 16, fontFamily: 'Helvetica', lineHeight: 1.2 }}>
          Notas:

          <ul>
            <li style={{ marginBottom: 5 }}>
              Ingresa precios en dólares por libra puestos en frontera de Texas (Laredo o McAllen).
            </li>

            <li style={{ marginBottom: 5 }}>
              Los precios deberán ser precios promedio de producto fresco de 21 días de antigüedad o menos a los que se cerró venta por volúmenes de una tarima o más EN LA SEMANA QUE ESTÁ CORRIENDO.
            </li>

            <li>
              El precio debe estar escrito solo con números y dos decimales, ejemplo: <b>2.43</b>
            </li>
          </ul>
        </div>
      );
    }

    return (
      <div style={{ fontSize: 16, fontFamily: 'Helvetica', lineHeight: 1.2 }}>
        Notas:

        <ul>
          <li style={{ marginBottom: 5 }}>
            Ingresar precios en dólares por libra puestos en Los Angeles (cruces por Nogales, Tijuana o Mexicali). Cuando venta se hizo puesto en frontera o en algún otro destino que no sea Los Angeles, favor de ingresar el precio puesto en frontera más el flete estimado a Los Angeles.
          </li>

          <li style={{ marginBottom: 5 }}>
            Los precios deberán ser precios promedio de producto fresco de 21 días de antigüedad o menos a los que se cerró venta por volúmenes de una tarima o más EN LA SEMANA QUE ESTÁ CORRIENDO.
          </li>

          <li>
            El precio debe estar escrito solo con números y dos decimales, ejemplo: <b>2.43</b>
          </li>
        </ul>
      </div>
    );
  }

  renderTabsFixed() {
    const { scrollPosition, mobile } = this.props;
    const $contentTabsMenu  = document.getElementById('content-tab-menu');
    const $contentTab = document.getElementById('content-tabs');
    const $mainHeader = document.getElementById('main-header');

    if ($contentTabsMenu && $contentTab && $mainHeader) {
      const easyToUserMenuOffset = offset($contentTabsMenu);
      const mainHeaderHeight = $mainHeader.offsetHeight;
      const contentOffset = offset($contentTab);
      const startPoint = easyToUserMenuOffset.top - $contentTabsMenu.offsetHeight + 15;
      const endPoint = contentOffset.top + $contentTab.offsetHeight;

      if (scrollPosition > startPoint && scrollPosition < endPoint) {
        return (
          <div style={{ position: 'fixed', zIndex: 100, top: mainHeaderHeight, left: 0, background: WHITE, width: '100%', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)' }}>
            <Grid centered>
              <Column width={mobile ? 16 : 9}>
                {this.renderTabs()}
              </Column>
            </Grid>
          </div>
        );
      }
    }

    return null;
  }

  renderTabs() {
    const { selectedTab } = this.state;

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: 60 }}>
        <div
          style={selectedTab === 0 ? styles.itemMenuSelected : styles.itemMenu}
          onClick={() => { this.changeTab(0); }}
        >
          Frontera de Texas
        </div>

        <div
          style={selectedTab === 1 ? styles.itemMenuSelected : styles.itemMenu}
          onClick={() => { this.changeTab(1); }}
        >
          Frontera de AZ o CA
        </div>
      </div>
    );
  }

  renderForm() {
    const { mobile } = this.props;
    const lastSunday = this.getLastSunday();
    const nextSaturday = this.getNextSaturday();
    let dateRange = '';

    if (nextSaturday.getMonth() !== lastSunday.getMonth()) {
      dateRange = `${lastSunday.getDate()} de ${months[lastSunday.getMonth()]} al ${nextSaturday.getDate()} de ${months[nextSaturday.getMonth()]}`;
    } else {
      dateRange = `${lastSunday.getDate()} al ${nextSaturday.getDate()} de ${months[nextSaturday.getMonth()]}`;
    }

    if (mobile) {
      return (
        <div style={{ position:'relative', marginTop: 50 }}>
          <Grid container centered stackable>
            <Row centered>
              <Column width={12} style={styles.container}>
                <Grid>
                  <Row>
                    <Column>
                      <p style={{ fontWeight: 'bold', fontSize: 16, fontFamily: 'Helvetica' }}>
                        Favor de ingresar los precios correspondientes a la semana del { dateRange } del { nextSaturday.getFullYear() }.
                      </p>

                      { this.renderNotes() }
                    </Column>
                  </Row>

                  <Row>
                    <Column>
                      <div id="content-tab-menu">
                        { this.renderTabs() }
                      </div>

                      { this.renderTabsFixed() }
                    </Column>
                  </Row>

                  <Row style={{ paddingBottom: 0 }}>
                    <Column style={{ height: 11800 }}>
                      <FadeInOutView eventType="change-tab" scroll={false}>
                        <div id='content-tabs'>
                          <IFrame
                            mobile={this.props.mobile}
                            selectedTab={this.state.selectedTab}
                          />
                        </div>
                      </FadeInOutView>
                    </Column>
                  </Row>
                </Grid>
              </Column>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div style={{ position: 'relative', marginTop: 30 }}>
        <Grid container centered stackable>
          <Row centered>
            <Column width={16} style={styles.container}>
              <Grid>
                <Row>
                  <Column>
                    <p style={{ fontWeight: 'bold', fontSize: 16, fontFamily: 'Helvetica' }}>
                      Favor de ingresar los precios correspondientes a la semana del { dateRange } del { nextSaturday.getFullYear() }.
                    </p>

                    { this.renderNotes() }
                  </Column>
                </Row>

                <Column>
                  <div style={{ fontSize: 16, fontFamily: 'Helvetica', lineHeight: 1.2 }}>
                    <p style={{ color: RED, fontWeight: 'bold' }}>
                      Tipos de producto
                    </p>

                    <ul>
                      <li style={{ marginBottom: 5 }}>
                        <strong>Producto no clasificado</strong>, carne proviene de ganado de engorda que en su mayoría no excede los 30 meses de edad.
                      </li>

                      <li>
                        <strong>Producto clasificado</strong> en base a madurez fisiológica y marmoleo. Clientes pagan primas de precios sobre producto no clasificado. Ejemplos: “Suprema”, “Premium”, “Choice”. Carne proviene de ganado de engorda con no más de 42 meses de edad.
                      </li>
                    </ul>
                  </div>
                </Column>

                <Row>
                  <Column>
                    <div id="content-tab-menu">
                      { this.renderTabs() }
                    </div>

                    { this.renderTabsFixed() }
                  </Column>
                </Row>

                <Row style={{ paddingBottom: 0 }}>
                  <Column style={{ height: 3050 }}>
                    <FadeInOutView eventType="change-tab" scroll={false}>
                      <div id='content-tabs'>
                        <IFrame
                          mobile={this.props.mobile}
                          selectedTab={this.state.selectedTab}
                        />
                      </div>
                    </FadeInOutView>
                  </Column>
                </Row>
              </Grid>
            </Column>
          </Row>
        </Grid>
      </div>
    );
  }

  render() {
    const currentDate = new Date().getDay();
    const notAllowed = [0,1,2,5,6];
    let content;

    if (notAllowed.includes(currentDate)) {
      content = this.renderMessage(currentDate);
    } else {
      content = this.renderForm();
    }

    return (
      <div>
        <Helmet>
          <title>EMEAT - Indice de precios mexicanos</title>
          <meta name="description" content="Market data and easy analytics of the U.S. meat industry, quickly customized by you, with affordable pricing and highly flexible plans." />
          <link rel="canonical" href="https://emeat-price-indices.com/" />
        </Helmet>

        { this.renderBanner() }
        { content }
      </div>
    );
  }
}


Landing.propTypes = {
  language: PropTypes.string,
  mobile: PropTypes.bool,
  scrollPosition: PropTypes.number
};


export default Landing;
