import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const { Column } = Grid;

const styles = {
  mainSection: {
    background: 'white',
    marginTop: 5,
    padding: 15,
    borderRadius: 5,
  },
};


function MainSection(props) {
  return (
    <Grid centered stackable style={props.style}>
      <Column width={14} style={styles.mainSection}>
        { props.children }
      </Column>
    </Grid>
  );
}


MainSection.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};


export { MainSection };
