
export default {
  generate() {
    const limit = 5;
    let key = '';

    for (let index = 0; index < limit; index++) {
      key += this.getKey();
    }

    return key;
  },
  getKey() {
    return Math.random().toString(36).slice(-5);
  }
};
