import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as CarouselLib from 'react-responsive-carousel';

const styles = {
  mobileMainContainer: {
    marginLeft: -24,
    marginRight: -24,
  }
};


class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowsHeight: 0,
      windowsWidth: 0,
    }
  }

  componentDidMount() {
    this.initWindowListener();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }


  // -----------------------
  // ------- methods -------
  // -----------------------
  onImageClick(image) {
    if (image.action) {
      window.location = image.action;
    }
  }

  initWindowListener() {
    const setDimensions = () => {
      this.setState({
        windowsHeight: window.innerHeight,
        windowsWidth: window.innerWidth
      });
    };

    window.onresize = () => {
      if (!this.unmounted) {
        setDimensions();
      }
    };

    setDimensions();
  }


  // ------------------------------
  // ------- render methods -------
  // ------------------------------
  renderImages() {
    const { value } = this.props;

    return value.filter((image) => image.src).map((image, index) => (
      <div
        key={index}
        style={{ cursor: image.action ? 'pointer' : 'default' }}
        onClick={() => { this.onImageClick(image); }}
      >
        <img src={image.src} alt="" />
      </div>
    ));
  }

  render() {
    const { style } = this.props;
    const { windowsWidth } = this.state;
    let containerStyle = style;

    if (windowsWidth < 768) {
      containerStyle = { ...styles.mobileMainContainer, ...style };
    }

    return (
      <div style={containerStyle}>
        <CarouselLib.Carousel
          infiniteLoop
          autoPlay
          showThumbs={false}
          showStatus={false}
          interval={7000}
          dynamicHeight
        >
          { this.renderImages() }
        </CarouselLib.Carousel>
      </div>
    );
  }
}


Carousel.propTypes = {
  value: PropTypes.array,
  style: PropTypes.object
};


export { Carousel };
